@font-face {
  font-family: 'NotoSansThaiBlack';
  src: local('NotoSansThaiBlack'),
    url('../src/fonts/NotoSansThai_Condensed-Black.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiBold';
  src: local('NotoSansThaiBold'),
    url('../src/fonts/NotoSansThai_Condensed-Bold.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiExtraBold';
  src: local('NotoSansThaiExtraBold'),
    url('../src/fonts/NotoSansThai_Condensed-ExtraBold.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiExtraLight';
  src: local('NotoSansThaiExtraLight'),
    url('../src/fonts/NotoSansThai_Condensed-ExtraLight.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiLight';
  src: local('NotoSansThaiLight'),
    url('../src/fonts/NotoSansThai_Condensed-Light.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiMedium';
  src: local('NotoSansThaiMedium'),
    url('../src/fonts/NotoSansThai_Condensed-Medium.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiRegular';
  src: local('NotoSansThaiRegular'),
    url('../src/fonts/NotoSansThai_Condensed-Regular.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiSemiBold';
  src: local('NotoSansThaiSemiBold'),
    url('../src/fonts/NotoSansThai_Condensed-SemiBold.ttf') format('ttf'),
}

@font-face {
  font-family: 'NotoSansThaiThin';
  src: local('NotoSansThaiThin'),
    url('../src/fonts/NotoSansThai_Condensed-Thin.ttf') format('ttf'),
}

@font-face {
  font-family: 'regular';
  src: url(../src/assets/font/NotoSansThai-VariableFont_wdth\,wght.ttf);
}

@font-face {
  font-family: 'light';
  src: url(../src/assets/font/static/NotoSansThai-Light.ttf);
}

@font-face {
  font-family: 'bold';
  src: url(../src/assets/font/static/NotoSansThai-Bold.ttf);
}

.font_regular {
  font-family: 'regular' !important;
}

body {
  margin: 0;
  font-family: 'light' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pointer {
  cursor: pointer;
}

u {
  font-family: 'regular';
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2C2C2E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor-pointer {
  cursor: pointer;
}
