.home-card-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 283px);
  grid-gap: calc((100% - (283px * 4)) / 3);

  @media screen and (min-width: 1024px) and (max-width: 1140px) {
    grid-template-columns: repeat(3, 283px);
    justify-content: center;
  }
}

.home-card-grid-item {
  padding: 0px;
  width: 283px;
}

/* Landscape tablet media query */
// @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
//   .home-card-grid-container {
//     display: grid;
//     grid-template-columns: repeat(4, 220px);
//     grid-gap: calc((100% - (220px * 4)) / 3);
//   }
  
//   .home-card-grid-item {
//     padding: 0px;
//     width: 220px;
//   }

//   .card-three{
//     max-width: 220px !important;
//     min-width: 220px !important;
//   }
// }

.main-container-bg {
  background: linear-gradient(to bottom left, rgb(54, 52, 52) 0%, #1C1C1E 36.5%);
  // linear-gradient(orange, orange) top left 100px;
  /*middle part */
  // linear-gradient(orange, green) center/100% calc(100% - 200px),
  /*Bottom part similary to the top*/
  // linear-gradient(to top right, red 49%, orange 50.5%) bottom center/100px 100px,
  // linear-gradient(orange, orange) bottom right/calc(50% - 49px) 100px;
  background-repeat: no-repeat;
  height: auto;
}
.list-group-item{
  cursor: pointer;
}
.card-primary-header {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: center;
  line-height: 24px;

  .header-title-cnt{
    word-break: break-word;
    max-width: 60%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1140px) {
    margin-left: 95px;
    justify-content: start;
  }
}

.header-sub-title {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  margin-left: 20px;
}

.search-icon {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.carousel-inner {
  .carousel-item{
    height: 455px !important;
  }
  img{
    height: 100%;
    border-radius: 0px !important;
  }
  .carousel-caption {
    left: 29.23px !important;
    text-align: left !important;
    width: 330px;
    padding: 0px;
    top:274px;

    p {
      width: 30rem;
    }
  }
}

// .carousel{
//   .carousel-indicators{
//     bottom: -3rem !important;
//   }
// }

//Mobile 
.carousel-root {

  .carousel-slider{
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .carousel .control-dots{
    position: relative !important;
  }

  .slider-wrapper {
    // .slider{
    //   margin: 45px !important;
    //   margin-left: 20px !important;
    //   margin-top: 4rem !important;
    // }
    
    li{
      margin-left: 15px;
      img {
        border-radius: 4px;
      }
      .banner-message{
        position: absolute;
        bottom: 0;
        color: #ffffff;
        text-align: left;
        left: 1rem;
        display: flex;
        align-items: flex-end;
        h3{
          font-weight: 700;
          font-size: 14px !important;
          line-height: 20px;
        }
        p{
          font-size: 12px;
          margin-bottom: 6px !important;}
      }
    }
    
  }

  .thumbs-wrapper{
    display: none;
  }
  .control-dots .dot{
    border-radius: 4px !important;
    width: 22px !important;
    height: 6px !important;
    margin: 0 6px !important;
  }
}
.crsl {
  // width: 400px;
  margin: auto;
}

.c-pointer{
cursor: pointer;
}


.header-page{

.mobile-view{
  display: none;
}


.container-bg{
  background-color: #000;
}

.list-group{
  float: right;
}

.list-group-item{
  background-color: #000;
}

.dropdown-toggle{
  background-color: #000;
  border: none;
}
.dropdown-toggle:active, .dropdown-toggle:hover, .dropdown-toggle:focus-visible{
  background-color: #000 !important;
  border: none;
  box-shadow:none;
}

.header-input{
  color: #fff;
  outline: none;
}


.form-control:focus{
  color: #fff !important;
  box-shadow: none !important;
}

.header-input::placeholder {
  color: #D8D8D8;
}

}

@media all and (min-device-width: 300px) and (min-device-height: 600px) and (orientation:portrait) {

 
  .carousel-root .slider-wrapper .slider{
    
  }
  .header-page{
    .mobile-view{
     display: block !important;

     ul{
      float: left;
     }

     .logo{
      width: 80px;
     }

     .list-group-item{
      padding-left: 5px;
      padding-right: 5px;
     }
    }

    .disktop-view{
      display: none !important;
    }
    
  }

  .card-primary-header{
    margin-top: 2rem;
    .header-sub-title{
      margin-left: auto;
    }
  }
 
}


.accordion {
  .accordion-button::after{
    background-image: url('../assets/icon/chevron.svg') !important;
    transform:rotate(-90deg)
  }
}

.max-width{
  max-width: 1140px;
  margin: 0 auto;
  overflow: hidden;
}
.product-pading{
  padding: 30px 50px;
}

.margin-60{
  margin-top: 60px !important;
}

.disktop-view{
  max-width: 1140px;
  margin: 0 auto !important;
}

.topic{
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
}


@media screen and (max-width : 465px) {
  .product-pading{
    padding: 0px 0px;
  }

  .bannermessage{
    padding: 10px;
  }

  .mb-carousel .slide div,.mb-carousel .slide div img{
    height: 170px !important;
  }
}

@media screen and (max-width : 768px) {

  .bannermessage{
    padding: 10px;
  }

  .mb-carousel .slide div,.mb-carousel .slide div img{
    height: 350px;
  }
}

/* Mobile devices (portrait) and tablets (portrait) */
@media only screen and (max-width: 1023px) {
  /* Your CSS styles for both mobile devices and tablets in portrait orientation here */

  .category-page .home-page-mobil-max-width{
    padding-left: 12px;
    padding-right: 12px;
  }
  .home-card-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 165px);
    grid-gap: 20px;
  }
  
  .home-card-grid-item {
    padding: 0px;
    width: 165px;
  }

  .home-page-mobil-max-width{
    max-width: 375px !important;
    margin: 0 auto;
  }

  .home-card-grid-container.bidding-card {
    display: grid;
    grid-template-rows: repeat(4, 174px);
    grid-gap: 20px;
    grid-template-columns: none;
  }

  .home-card-grid-container.bidding-card .home-card-grid-item {
    padding: 0px;
    width: 100%;
  }

  .why-us{
    max-width: 375px !important;
    margin: 0 auto;
  }

  .mb-carousel .slide div,.mb-carousel .slide div img{
    
    height: 350px;
    object-fit: cover;
  }

}

.carousel-indicators [data-bs-target]{
  width: 22px !important;
  height: 6px !important;
  border-radius: 8px !important;
  border-top: none !important;
  border-bottom: none !important;
}

.carousel-indicators{
  margin-bottom: 0 !important;
  bottom:-30px !important;
}