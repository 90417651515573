.success-change-pass {
  position: absolute;
  right: 2rem;
  top: 7rem;
  background-color: green;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  border-radius: 6px;

  h6 {
    margin-bottom: 0rem !important;
  }
}

  @media screen and (max-width: 500px) {
    .bidding-card {
      .card-three {
        height: 174px !important;
        min-height: 174px !important;
        .top {
          height: 100%;
          width: 50%;
          float: left;
          img {
            // padding: 1rem !important;
            height: 100% !important;
          }
        }

        .main_container {
          height: 100%;
          width: 50%;
          float: right;
          min-height: auto !important;
          border-radius: 4px !important;

          div:nth-child(2){
            flex-direction: column-reverse;
          }
          .like_cnt {
            background-color: #48484a;
            border-radius: 28px;
            padding: 2px 10px;
            width: max-content;
            margin-left: auto;
            position: absolute !important;
            bottom: 45px !important;
            right: 12px !important;
            top: auto !important;
          }

          .no-txt {
            margin-top: 1rem;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
          }
        }
      }
    }
  }

.product {
  .participate-btn-conainer {
    background-color: #1c1c1e;
    text-align: center;
  }

  .participate-btn {
    width: 380px;
    border-radius: 4px;
    height: 50px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
  }
}

.bidding-page {
  background-color: #1c1c1e;
  .productlist-title {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 0rem !important;
    color: #ffffff;
  }
  .deposit_pay_btn {
    border: 1px solid #cacaca;
    border-radius: 4px;
    width: 100%;
    background-color: transparent;
    min-height: 45px;
    color: #cacaca;
    font-family: "regular";
  }

  .number-txt {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }

  .product_name {
    font-size: 24px;
    font-weight: 300;
    color: #fff;
  }

  .verify-icon {
    width: 60px;
    height: 60px;
  }

  .like_cnt {
    white-space:nowrap;
    background-color: #48484a;
    border-radius: 28px;
    padding: 2px 10px;
    width: max-content;
    margin-left: auto;
    margin-right: 15px;
    cursor: pointer;
  }

  .like_img {
    width: 18px;
    height: 18px;
  }

  .like_count {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  .ignite_txt {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 16px;
  }

  .amount_txt {
    color: #a9a9a9;
    font-size: 24px;
    font-weight: 300;
  }

  .product-detail {
    p {
      color: #d8d8d8;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  .product-table {
    width: 100%;

    .title {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: normal;
      width: 30%;
    }

    td {
      color: #d8d8d8;
      font-size: 14px;
      font-weight: 300;
      padding: 10px 0px;
      line-height: 20px;
      letter-spacing: -0.14px;
    }

    tr {
      border-bottom: 1px solid #48484a;
    }
  }

  .condition-section {
    background-color: #48484a;
    float: left;
    border-radius: 4px;

    .title {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .cnt {
      color: #d8d8d8;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
    }

    table {
      .title {
        color: #d8d8d8;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
      }

      .amount {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }

      td {
        padding: 5px 20px;
        text-align: center;
      }
    }
  }

  .bit_with_confidence_btn {
    background-color: #000000;
    padding: 10px;
    width: 100%;
    min-height: 55px;
    color: #fff;
    border-radius: 4px;
    border: none;
  }

  // .bit_with_confidence_btn button {
  //   height: 100px;
  // }

  .bit_with_confidence_btn img {
    height: 100px;
  }

  .action-container {
    background-color: #2c2c2e;
    border-radius: 8px;

    .timer_txt {
      color: #d8d8d8;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    .timer {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 20px;
    }

    .amount_txt {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }

    .amount {
      color: #fff;
      font-size: 42px;
      font-weight: 700;
    }

    hr {
      border-color: #48484a;
      opacity: 1;
    }

    .amt_btn {
      width: 100px;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      border: 1px solid #cacaca;
      background-color: #2c2c25;
      margin-left: 5px;
      margin-right: 5px;
    }
    .active_amt_btn {
      width: 100px;
      height: 50px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: #2c2c25;
      border: 1px solid #fff;
      background-color: #fff;
    }

    .action_price_txt {
      color: #d8d8d8;
      font-size: 10px;
      font-weight: 500;
    }

    .action_price_btn {
      background-color: #48484a;
      color: #d8d8d8;
      height: 45px;
      width: 100%;
      border: none;
      border-radius: 4px;
    }

    .bit_btn {
      background-color: #c10f1b;
      color: #d8d8d8;
      height: 45px;
      width: 100%;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    .buy_cnt {
      font-size: 14px;
      color: #d8d8d8;

      b {
        color: #fff;
      }
    }

    .other_bidding_list_tbl {
      color: #fff;
      width: 100%;

      td:nth-child(2) {
        text-align: right;
      }

      .left_head {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 2rem;
      }

      .right_head {
        font-size: 12px;
        font-weight: 300;
        padding-bottom: 2rem;
      }

      .main_content {
        font-size: 14px;
        font-weight: 400;
      }

      .sub_content {
        font-size: 12px;
        font-weight: 200;
        color: #737576;
        padding-top: 2px;
        padding-bottom: 10px;
      }

      .amount {
        color: #807f7e;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}


.faq {
  .search-icon {
    background-color: #48484a;
  }

  .header {
    font-size: 36px;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }

  .sub_title {
    font-size: 24px;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }

  background-color: #1c1c1e;

  input {
    background-color: #48484a;
    height: 40px;
    color: #fff;
    outline: none;
    margin-left: 30px;
  }

  :focus {
    background-color: #48484a !important;
    box-shadow: none;
    color: #fff;
  }

  .header-input::placeholder {
    color: #D8D8D8;
  }

  .accordion-item {
    border: none;
  }

  .accordion .accordion-button:not(.collapsed) {
    background-color: #2c2c2e !important;
  }

  .accordion-header {
    button {
      background-color: #2c2c2e;
      border: none;
      color: #fff;
      box-shadow: none;
    }

    :focus {
      background-color: #2c2c2e !important;
    }

    .img-container {
      width: 40px;
    }
  }

  .accordion-body {
    background-color: #2c2c2e;
    color: #d8d8d8;
    font-size: 14px;
    font-weight: 300;
    padding-top: 0px;

    .cnt {
      padding-left: calc(40px + 0.5rem);
    }
  }

  .nav {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
  }

  .nav-item {
    border-bottom: 1px solid #48484a;
    max-width: 100%;
    // width:-webkit-fill-available;
  }

  .nav-link {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin: auto;
    border: none !important;
    background-color: transparent !important;

    &:hover,
    &:focus {
      border: none !important;
      background-color: transparent !important;
    }
  }

  .active {
    background-color: transparent !important;
    color: #fff !important;
    border: none !important;
  }
}

.change-password {
  background-image: url(".././assets/Image-Bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .header {
    font-size: 24px;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }

  .form-label {
    font-size: 10px;
    color: #fff;
  }

  .form-control-container {
    max-width: 450px;
    margin: auto;
  }

  .form-control {
    background-color: #48484a !important;
    border: none !important;
    border-radius: 4px !important;
    color: #fff;
    height: 45px;
  }

  .form-control-error {
    border: 1px solid red !important;
  }

  :focus {
    outline: none;
  }

  .button {
    background-color: #1c1c1e;
    color: #fff;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 4px;
    height: 45px;
    font-size: 18px;
  }

  .password-checker {
    color: #ffffff;
    max-width: 100%;
    margin: 0 auto;

    span {
      margin-left: 0.5rem !important;
    }

    .circle {
      width: 15px;
      height: 15px;
      float: left;
      background-color: #737576;
      border-radius: 50px;
    }

    .txt {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      margin-left: 10px;
    }
  }
  .register-second-page{
    color:#ffffff;
  }
}

.my-favorite {
  background-image: url(".././assets/Image-Bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .title {
    font-size: 36px;
    font-weight: 300;
    color: #fff;
  }

  .empty-favorite {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      width: 150px;
      height: 150px;
    }

    .txt1 {
      font-size: 16px;
      font-weight: 300;
      color: #fff;
    }

    .txt2 {
      font-size: 14px;
      font-weight: 300;
      color: #fff;
      text-decoration: underline;
    }
  }

  .bottom_btn {
    background-color: #48484a;
    font-size: 14px;
    font-weight: 400;
    color: #acacac;
    min-width: 150px;
    height: 35px;
    border-radius: 4px;
    border: none;
  }

  .favorite-list {
    background-color: #2c2c2e;
    border-radius: 4px;
    cursor: pointer;

    .verify-icon-home{
      width: 48px;
      height: 48px;
    }
    
    .favorite-list-row{
      height: 115px;
    }

    .button {
      font-size: 15px;
      font-weight: 400;
      background-color: #2c2c2e;
      height: 45px;
      // min-width: 180px;
      width: 165px;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 4px;
      margin-left: auto;
      margin-right: 15px;
    }

    .green {
      border: none !important;
      background-color: #02702b !important;
    }

    .close_gray{
      border: none !important;
      background-color: #48484a !important;
      color: #737576 !important;
    }
    .win-txt{
      font-size: 12px;
      font-weight: 500;
      color: #FFCA0A;
      line-height: 20px;
      letter-spacing: -0.12px;
      float: left;
      margin-right: 10px;
    }

    .left {
      background-image: url(".././assets/Image Product 2.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 115px;
      height: 115px;
      background-position: 80%;
      border-radius: 4px 0px 0px 4px;
    }

    .like_cnt {
      background-color: #48484a;
      border-radius: 28px;
      padding: 2px 10px;
      width: max-content;
      margin-left: auto;
      margin-right: 15px;
      cursor: pointer;
    }

    .like_img {
      width: 18px;
      height: 18px;
    }

    .like_count {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    .main_container {
      //   height: 40%;
      //   background-color: #2C2C2E;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: end;
      //   position: relative;
      // width: calc(100% - 150px);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .truncate-line2{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .no-txt {
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
      }

      .name {
        word-break: break-all;
        font-size: 14px;
        font-weight: 600;
        color: #d8d8d8;
      }

      .amount-txt,
      .time-txt {
        font-size: 12px;
        color: #a9a9a9;
        font-weight: 500;
      }

      .amount,
      .time {
        font-size: 14px;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width : 761px) {
 .my-favorite {
  .favorite-list{
    min-height: 115px;
    min-width: 300px;
    .left{
      width:115px;
      height: 115px;
    }

    .name {
      word-break: break-all;
      font-size: 14px;
      font-weight: 600;
      color: #d8d8d8;
    }

    .no-txt {
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
    }
    .time {
      font-size: 16px;
      color: #fff;
      font-weight: 700;
    }
    
  }
 }
}

.address-page {

  .container{
    max-width: 912px !important;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus{
    background-color: transparent !important;
    color: #fff !important;
    height: 40px;
    border-radius: 4px;
    border: 1px solid;
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    isolation: unset;
    border-color: unset;
    color: #fff !important;
    border: none;
  }

  .nav-tabs{
    flex-direction: row;
  }
  .nav-link{
    margin: auto;
    transition:none;
    width: 100%;
  }
  .nav-item{
    width: 50%;
  }
  .nav-tabs{
    border-bottom: none;
  }

  .nav-link{
    color: #fff;
  }

  background-image: url(".././assets/Image-Bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;

  .title {
    font-size: 32px;
    font-weight: bold;
  }

  .sub_title {
    font-size: 22px;
    font-weight: 300;
  }

  .add_address {
    font-size: 18px;
    background-color: #48484a;
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 45px;
    max-width: 450px;
  }

  .address-container {
    width: 100%;
    background-color: #48484a;
    min-height: 100px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .address-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    img {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
    .details {
      font-size: 14px;
      font-weight: 300;
      word-break: break-all;
    }

    input[type="radio"] {
      position: relative;
      cursor: pointer;
    }

    input[type="radio"]:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -4px;
      left: -1px;
      border-radius: 50px;
      background-color: #2c2c2e;
    }

    input[type="radio"]:checked:after {
      content: "";
      display: block;
      width: 8px;
      height: 12px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: -2px;
      left: 5px;
    }
  }

  .submit_btn {
    width: 100%;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 400;
    height: 45px;
    text-align: center;
    background-color: #1c1c1e;
    color: #fff;
    border: 1px solid #fff;
    max-width: 450px;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }

  .warning_msg {
    font-size: 12px;
  }
}

////////////////CATEGORY/////////////////@at-root
///
///
///
///
///

.category-container {
  p {
    margin-bottom: 0rem !important;
  }

  .actionTypeFilter {
    .filter-checkbox {
      width: 0px;
      margin-bottom: 1rem;
    }

    input[type="radio"] {
      position: relative;
      cursor: pointer;
    }

    input[type="radio"]:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -4px;
      left: -1px;
      border-radius: 50px;
      background-color: #48484a;
    }

    input[type="radio"]:checked:after {
      content: "";
      display: block;
      width: 8px;
      height: 12px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: -2px;
      left: 5px;
    }

    input[type="checkbox"] {
      position: relative;
      cursor: pointer;
    }

    input[type="checkbox"]:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      // top: 0;
      // left: 0;
      border-radius: 50px;
      background-color: #48484a;
    }

    input[type="checkbox"]:checked:after {
      content: "";
      display: block;
      width: 8px;
      height: 12px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }

    label {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      padding-left: 2rem;
    }
  }

  .filter-products {
    background-color: #c20e1a;
    border-radius: 4px;
    outline: transparent;
    border: transparent;
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    margin-top: 3rem;
  }

  .category_title {
    font-size: 24px;
    color: #ffffff;
    padding-bottom: 1.5rem;
    font-weight: 24px;
  }

  .cat-left-cont {
    background-color: #2c2c2e;
    border-radius: 5px;
    padding: 15px;
    // position: absolute;
    // height:-webkit-fill-available;
    .search_list_cont p {
      font-size: 16px;
      color: #ffffff;
    }

    .filter_text {
      color: #ffffff;
      font-size: 24px;
      font-weight: 300;
    }

    .default_text {
      color: #a9a9a9;
      font-size: 16px;
      font-weight: 300;
      align-self: center;
    }

    .sorting_date {
      p {
        color: #d8d8d8;
        font-size: 16px;
        padding-bottom: 1rem;
      }

      .sorting_date_MyBidding{
        font-size: 10px;
        font-weight: 500;
      }

      select {
        background-color: #48484a;
        color: #ffffff;
        font-size: 16px;
        border: transparent;
      }

      // .form-select{
      //     background-image: url('../assets/icon/chevron-bottom.svg');
      // }
    }
  }

  .category_list {
    background-color: #000;
    margin-bottom: 2rem;
    border-radius: 10px;

    img {
      height: 60%;
      width: 100%;
      padding: 10px;
    }

    .main_container {
      height: 40%;
      background-color: #2c2c2e;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      border-radius: 0px 0px 10px 10px;

      .no-txt {
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
      }

      .name {
        word-break: break-all;
        font-size: 14px;
        font-weight: 400;
        color: #d8d8d8;
      }

      .like_cnt {
        background-color: #48484a;
        border-radius: 28px;
        padding: 2px 10px;
        width: max-content;
        margin-left: auto;
        position: absolute;
        top: -17px;
        cursor: pointer;
        right: 12px;
      }

      .like_img {
        padding: 0px !important;
        width: 18px;
        height: 18px;
      }

      .like_count {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        margin-left: 0.5rem;
      }

      .amount-txt,
      .time-txt {
        font-size: 12px;
        color: #a9a9a9;
        font-weight: 500;
      }

      .amount,
      .time {
        font-size: 16px;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .category_modal_conta {
    background-color: #1c1c1e;
    color: #fff;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 999;
    bottom: 0;
  }
}

.category_modal_conta {
  position: absolute;
  left: 0px;
  right: 0px;
  background-color: #1c1c1e;
  z-index: 999;
  color: #ffffff;

  p {
    margin-bottom: 0rem !important;
  }

  .filter_products_title {
    font-size: 24px;
    font-weight: 400;
  }

  .default_text {
    color: #a9a9a9;
    font-size: 16px;
    font-weight: 100;
  }

  .filter-products {
    background-color: #c20e1a;
    border-radius: 4px;
    outline: transparent;
    border: transparent;
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    margin-top: 3rem;
  }

  .filter_sorting_date {
    p {
      color: #d8d8d8;
      font-size: 16x;
      padding-bottom: 1rem;
    }

    select {
      background-color: #48484a;
      color: #ffffff;
      font-size: 16px;
      border: transparent;
    }

    .form-select {
      background-image: url("../assets/icon/chevron-bottom.svg");
    }
  }
}

// @media screen and (max-width: 700px) {
//   .cat-left-cont_mobile {
//     display: none;
//   }
// }

/////////////////////// CART //////////////////
///
///
///
.my-bidding {
  .search-cont input {
    color: #ffffff;
    border-radius: 8px;
    opacity: 1;
    background-color: rgba(72, 72, 74, 1);
    font-size: 18px;
    font-weight: 500;
    outline: none;
    border: none;
  }

  .header-input::placeholder {
    color: #D8D8D8;
  }

  .search-cont .search-icon {
    opacity: 1;
    background-color: rgba(72, 72, 74, 1);
  }

  .form-control:focus {
    box-shadow: none;
  }

  .address_text_my_item {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
  }

  .my-iems-truck {
    color: #ffffff;
    font-size: 14px;
    align-items: center;

    p {
      margin-bottom: 0.5rem !important;
    }

    img {
      width: 2rem;
    }
  }

  .verify-icon-home{
    position: absolute;
  }
}

.header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-results {
  color: #ffffff;
  font-size: 18px;
  padding: 1rem 0px;
}

.input-group-prepend {
  .search-icon {
    padding: 10px;
  }
}

.input-group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: 345px;
    height: 44px;
}

.terms-of-use {
  .number {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #48484a;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 30px;
    top: 15px;
    // padding-left: 20px;
  }
 
  .relative {
    position: relative;
  }
}

@media screen and (max-width : 426px) {
  .terms-of-use {
    .number {
      position: absolute;
      width: 30px;
      height: 30px;
      
    }
}
}

@media screen and (max-width : 992px) {
  .cat-left-cont{
    height: auto !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
  }

}


.bidding_amount_cancel_btn {
  position: absolute;
  right: 20px;
  top: 36px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.conformation-container {
  .cancel_btn {
    background-color: #48484a;
    color: #737576;
    height: 45px;
    border-radius: 4px;
    border: none;
    width: 100%;
  }
  .bid_btn {
    background-color: #02702b;
    color: #fff;
    height: 45px;
    border-radius: 4px;
    border: none;
    width: 100%;
  }

  .auction_txt {
    color: #d8d8d8;
    font-size: 16px;
    text-align: center;
  }

  .buyout_text {
    color: #d8d8d8;
    font-size: 16px;
    text-align: left;
  }

  .auction_amt {
    color: #d8d8d8;
    font-size: 24px;
    text-align: center;
    font-family: "bold";
  }
  .full-bid-btn {
    color: #d8d8d8;
    background-color: #02702b;
    font-size: 16px;
    color: #fff;
    text-align: center;
    height: 45px;
    width: 100%;
    border:none;
    border-radius: 5px;
  }
  border-radius: 4px;
  border: none;
  width: 100%;
}

// .faq .accordion{
// //  max-width: 553.5px !important;
 
// }
@media screen and (max-width: 670px)
{
  .nav{
    display: flex;
    flex-direction: column;
    width:-webkit-fill-available ;
  }
}


#hot-item{
  margin-top: 3rem;
}

.why-us{
  margin-bottom: 2rem;
}

/* Mobile devices (portrait) and tablets (portrait) */
@media only screen and (max-width: 1023px) {
  /* Your CSS styles for both mobile devices and tablets in portrait orientation here */
  .mobile-prod-img .verify-icon-home{
    top: 15px;
    left: 15px;
  }
  #hot-item{
    margin-top: 1rem;
  }

  .bidding_amount_cancel_btn{
    top:20px
  }

  .bidding-page .product-detail p{
    line-height: 18px;
  }

  .address-page{
    .edit_delet_icon{
      display: flex;
      position: absolute;
      right: 0;
      bottom: 8px;
    }
  }

  .product-disktop-view{
    display: none !important;
  }

  .product-mobile-view{
    display: block !important;
  }

  .product-mobile-view{

    .counter-detail{
      position: absolute;
      color: #fff;
      left: 10px;
      // bottom: 18px;
      .txt{
        font-size: 12px;
      }
      .time{
        font-size: 24px;
        font-weight: 700;
      }

      @media screen and (min-width: 1023px) {
        display: block;
      }
    }

    .small-img {
      background-color: #000;
      margin-top: 0px;
      margin-bottom: 10px;
      height: 87px;
      .thumbnail{
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .product-footer{
        position: fixed;
        width: 100%;
        background-color: #000;
        height: 100px;
        bottom: 0;
        left: 0;
        color: #fff;
        justify-content: space-between;
        z-index: 1;
        
        .text-yellow{
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.12px;
        }

        .verify-icon-home{
          position:relative !important;
          top: 0 !important;
          left: 0 !important;
        }
        .amount{
          font-size: 24px;
          font-weight: 700;
          line-height: 20px;
        }
        .time{
          font-size: 12px;
          font-weight: 500;
        }
        .participate-in-bid-btn{
          height: 45px;
          background-color: transparent;
          border:1px solid #fff;
          border-radius: 4px;
          color:#fff;
          min-width: 165px;
        }

        .bid-close-btn{
          background-color: #02702C;
          height: 45px;
          border:1px solid #02702C;
          border-radius: 4px;
          color:#fff;
          min-width: 165px;
        }

     
    }
    background-color: #1c1c1e;
   .product-img-slider .main-img{
    @media screen and (max-width: 500px){
      height: 375px !important;
      width: 100% !important;
    }
   }

   .back_arrow_img{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 22px;
   }
  }
  .verify-icon-home{
    position: absolute;
  }
  .name-fav-icon{
    .number-container{
      width: 280px;
    }
  }

  .bidding-page .like_cnt{
    margin-right: 0px !important;
  }
  .bidding-page .product_name{
    padding-right: 15px;
    line-height: 30px;
  }

  .bidding-page .number-txt{
    line-height: 30px;
  }
 
  .bidding-page .amount_txt{
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.12px;
  }
  .bidding-page .opening-bid-number-txt{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }
  .bidding-page .condition-section{
    float: none;
  }
  .condition-section table{
    width: 100%;
  }
  .bidding-page .condition-section table td{
    padding: 5px 0px;
  }
  .action-container{
    border-radius: none;
    background-color: #1c1c1e !important;
  }
  .back_button_cont_bg{
    background-color: #000000;
  }
  .bidding-page .action-container .amt_btn{
    background-color: transparent;
    margin-left: 0px;
    margin-right: 0px;
  }
  .img-and-price-cont .prod-img{
    width: 105px;
    border-radius: 4px;
  }

  .img-and-price-cont{
    .number-txt{
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }
    .product-name{
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
    }

    .amount_txt{
      font-size: 12px !important;
      line-height: 20px !important;
      font-weight: 300 !important;
    }
    .amount{
      font-size: 26px !important;
      line-height: 16px !important;
      font-weight: 700 !important;

      .verify-icon-home{
        position: relative;
        width: 25px;
        height: 25px;
        top: -2px;
        right: 4px;
      }
    }
  }
}
.product-mobile-view{
  display: none;
}
.mobile-view-page2{
  min-height: 100vh;
}