.own-form {
  
  
  .cart {
    border: 2px solid black;
    margin-bottom: 20px;
  }
  
  .cart-details h3 {
    display: inline-block;
    margin: 5px;
  }
  
  .checkout form {
    margin-left: 10px;
  }
  
  form button {
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: none;
    display: block;
    font-size: 20px;
    outline: none;
    padding: 10px;
    transition: all 0.4s;
    width: 100%;
    color: #ffffff;
  }
}