.register_inputField{
    background-color: #48484A !important;
    border: 0px !important;
    border-radius: 4px !important;
    outline: none;
    padding: 10px !important;
    margin-top: 10px;
    color: white !important;
}
.register_inputField_error{
    background-color: #48484A !important;
    border: 1px solid #aa4242 !important;
    border-radius: 4px !important;
    outline: none;
    padding: 10px !important;
    margin-top: 10px;
    color: red !important;
}
.input_error_text{
    font-size: 12px;
}
.register_button{
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    background-color: transparent;
    color: #D8D8D8;
    font-size: 18px;
    padding: 10px 0px 10px 0;
}
.new_account_text{
    text-decoration: underline;
    font-size: 14px;
}
.password_rules_text{
    color: #D8D8D8;
    font-size: 14px;
}

.aboutus-title{
    text-align: center;
    padding: 3rem 0rem;
    font-size: 36px;
}