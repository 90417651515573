.product-img-slider {
  .main-img {
    height: 435px !important;
    width: 435px !important;
  }

  .start-img {
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;

    @media screen and (max-width: 1023px) {
      justify-content: end;
    }

    .small-img {
      width: 655px;
      scrollbar-width: none;

      @media screen and (max-width: 1023px) {
        overflow-x: auto;
        width: 50%;
      }

      @media screen and (max-width:500px) {
        height: 55px;
      }

      .thumbnail {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
        img {
          width: 86px;
          height: 86px;
          border-radius: 4px;

          @media screen and (max-width:500px) {
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }

}