.main-cont {
    padding-bottom: 4rem;

    .input1{
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 18px;
        color: #ffffff;
        border-bottom: 0.1px solid #D8D8D8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span{
            font-size: 10px;
            color: #D8D8D8;
        }
        input{
            border-bottom: none !important;
        }
    }
    .form-main-cont {
        flex-direction: row;
        display: flex;
        justify-content: center;
        .form-container {
            background-color: #2C2C2E;
            color: #ffffff;
            border-radius: 4px;
            padding: 20px 24px;
            flex-direction: column;
            display: flex;
            height: 100%;
           
            img{
                width: 10px;
            }
            
            .input-cont {
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                label{
                    font-size: 10px;
                    color: #D8D8D8;
                    padding-bottom: 10px;
                }
                input {
                    background-color: transparent;
                    outline: none;
                    border: none;
                    border-bottom: .1px solid #D8D8D8;
                    font-size: 18px;
                    color: #ffffff;
                    padding-bottom: .5rem;
                }
            }
        }

        .form_right_cont {

            align-self: center;
            display: flex;
            border: 1px solid #D8D8D8;
            justify-content: center;
            border-radius: 4px;
            padding: 5px 0;
            width: 151px;
            height: 30px;
            p {
                margin-bottom: 0rem;
                font-size: 14px;
                font-weight: 400;
            }

        }
    }

    //SECTION 3
    .table-container {
        background-color: #2C2C2E;
        color: #ffffff;
        border-radius: 4px;
        padding: 20px;
        width: 100%;
        overflow-x: auto;

        table {
            thead {
                padding-bottom: 1rem;
                color: #CACACA;
                font-size: 12px;
                border-bottom: .1px solid #D8D8D8;

                th {
                    padding-bottom: .5rem;
                }
            }

            tbody td {
                padding-top: .5rem;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    //SECTION2
    .middle-container {
        background-color: #2C2C2E;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        padding: 20px;

        p {
            margin-bottom: 0rem;
            font-size: 12px;
        }

        .left_cont {
            align-items: center;
            color: #ffffff;

            h1 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
        
        .changeps_header{
            font-size: 18px;
        }

        .right_cont {
            align-items: center;
            display: flex;
            border: 1px solid #D8D8D8;
            justify-content: center;
            border-radius: 4px;
            height: 30px;
            width: 300px;
            min-width: 150px;
            background-color: #2c2c2e;
            color: #fff;
            margin: 0 auto;
            font-size: 14px;
            font-weight: 400;
            p {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .main-cont  .middle-container .right_cont {
      width: 146px;
    }
}


a{
    text-decoration: none !important;
    
  }
.my-account-address-container{
    overflow: auto;
    max-height: 45vh;
    display: flex;
    flex-direction: column-reverse;
}
.my-account-page{
    background-image: url('../../assets/Image-Bg-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    .address-txt{
        font-size: 18px;
        font-weight: 400;
        border-bottom: 0.1px solid #D8D8D8;
        padding-bottom: 15px;
        word-break: break-all;
    }
}

/* Mobile devices (portrait) and tablets (portrait) */
@media only screen and (max-width: 1023px) {
    /* Your CSS styles for both mobile devices and tablets in portrait orientation here */
    .my-account-address-container{
        max-height: none !important;
    }
}