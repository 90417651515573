.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.overlay {
  /* position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); */
}

/* absolute w-1/4 top-50 p-3 text-center left-50 bg-white border border-gray-400 */
.loading-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
  background-color: #ffffff98;
  text-align: center;
  position: fixed;
  z-index: 100001;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #9ca3af;
  border: 1px solid #dee2e6 !important;
}
