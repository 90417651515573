.main-bg {
    background-color: #000000;
    padding:50px 20px 50px 20px;

    .footer_about_us_cont {
        // width: 400px;
    }
    .social-links{
        justify-content: space-between;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        color: #D8D8D8;
    }

    ul {
        list-style-type: none;
        padding: 0px;

        li {
            cursor: pointer;
            padding-top: 10px;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
        }

        li:not(:first-child) {
            font-size: 14px;
            color: #D8D8D8;
            cursor: pointer;
        }
    }

    .follow_cont {
        padding-top: 36px;
        color: #ffffff;
    }
   

    .icon_bg {
        margin-right: 15px;
        min-width: 41px;
        cursor: pointer;
        // width: 50px;
        // height: 50px;
        // border-radius: 50%;
        // background-color: #ffffff;
    }
}


.float-right{
float:right !important;
}

.footer_copyright {
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
    padding: 20px 0 20px 0;
    margin-bottom: 0px;
}

.footer-secondary {
    text-align: center;
    background-color: #000000;

    .bg {
        background-image: url('.././assets/Image-Bg-1.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-bottom-left-radius: 40px;
    }


    .primary-cont {
        font-size: 32px;
        font-weight: 400;
        color: #fff;
        text-align: left;
    }

    .secondary-cont {
        font-size: 16px;
        font-weight: 400;
        color: #D8D8D8;
        text-align: left;
    }

    .input {
        width: 100%;
        height: 45px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: none;
    }

    .footer-secondary-btn {
        width: 120px;
        height: 45px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: none;
        color: white;
        background: linear-gradient(to right, #636363, #2D2C2B);
    }

    .field-cnt {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.accordion {
    --bs-accordion-bg: transparent !important;
   
    .accordion-item {
        background-color: transparent;
        border: none;
    }
    .accordion-header button{
        color: #ffffff !important;
    }
    .accordion-button:not(.collapsed){
        background-color: transparent !important;
        outline: none !important;
    }
    // .accordion-button::after{
    //     background-image: url('../assets/icon/chevron.svg') !important;
    //     transform:rotate(-90deg)
    // }
}

.mobile-view {
    display: none;
}

.logofooter{
    width: 184px;
    height: 60px;
}

@media screen and (max-width: 500px) {


.footer-secondary{
    .row{
        padding: 0px !important;
        padding-top: 2rem !important;
    }
    .field-cnt{
            margin-left: none;
            margin-left: inherit;
            width: 100%;
    }
}

    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }
}

.mobile-view{
    .accordion-button:not(.collapsed){
        box-shadow:none !important
    }

    .accordion .accordion-button::after{
        transform:rotate(180deg);
    }

    .accordion-button:not(.collapsed)::after{
        transform:rotate(270deg);
    }

    .accordion .accordion-button:focus {
        outline: none !important;
        box-shadow:none;
    }

    .accordion-body
    {
        padding: 0;
        padding-left: 40px;
    }
}

.max-width{
    max-width: 1140px;
    margin: 0 auto;
  }

  /* Mobile devices (portrait) and tablets (portrait) */
@media only screen and (max-width: 1023px) {
    /* Your CSS styles for both mobile devices and tablets in portrait orientation here */

    .footer-secondary .row{
        padding-bottom: 1rem !important;
    }

}