.main_bg {
  background-color: #201f1f;
  color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 5rem;

  background-image: url("../../assets/Image-Bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .nav-tabs {
    display: none;
  }

  p {
    margin-bottom: 0rem !important;
  }

  .left_container {
    align-self: center;
  }

  .right_container {
    border-left: 1px solid #ffffff;
    display: flex;
    justify-content: center;
  }

  .left_cont {
    padding-top: 3rem;
  }

  .left_cont_list {
    display: flex;
    align-items: center;

    h2 {
      font-size: 28px;
      color: #ffffff;
    }

    p {
      font-size: 14px;
      text-align: justify;
      color: #d8d8d8;
    }
  }

  .divider {
    width: 0px;
    padding: 0.1px;
    height: auto;
    background: #ffffff;
    margin: 0px 100px 0px 100px;
  }

  .register_title {
    font-size: 32px;
    line-height: 1;
    font-family: "light";
  }

  .register_subtitle {
    font-size: 22px;
    font-family: "light";
  }

  .center_main_cont {
    align-items: center;
    padding-top: 4rem;
  }

  .register_button_cont {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .password_rule_item {
    display: flex;
    align-items: center;
    .valid {
      div {
        margin-top: 1px;
      }
      span {
        padding-top: 0px !important;
        padding-left: 0.5rem;
        font-size: 12px;
      }
    }
    .invalid {
      div {
        margin-top: 1px;
      }
      span {
        padding-top: 0px !important;
        padding-left: 0.5rem;
        font-size: 12px;
      }
    }
  }

  .input-group-text {
    background-color: transparent;
    border: transparent;

    input {
      // background-color: green;
      border: transparent;
    }

    .form-check-input[type="checkbox"] {
      border-radius: 50%;
      // width: 1.3em;
      // height: 1.3em;
    }
  }

  .terms_and_condition {
    display: flex;
    align-items: flex-start;
    font-size: 14px;

    .form-check-input[type="checkbox"] {
      border-radius: 50%;
      width: 1.3em;
      height: 1.3em;
    }
  }
}

.register-second-page {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .dropdown {
    button {
      background-color: #48484a;
      border: none;
      border-radius: 4px;
      width: 100%;
      height: 45px;
      margin-top: 10px;
      text-align: left;
    }
    .dropdown-toggle::after {
      position: absolute;
      right: 10px;
      top: 2rem;
    }
  }

  .field-lable {
    font-size: 10px;
    font-weight: 500;
  }

  .warning_msg {
    font-size: 12px;
  }

  .submit-btn {
    height: 45px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background-color: #1c1c1e;
    color: #fff;
  }

  .title {
    font-size: 38px;
    font-weight: 300;
    text-align: center;
  }

  .sub_title {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
  }

  .content-style {
    font-size: 14px;
    font-weight: 400;
  }
}

.register_button {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: transparent;
  color: #d8d8d8;
  font-size: 18px;
  padding: 10px 0px 10px 0;
}

#acceptTerms {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

#acceptTerms::after {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: rgb(115, 117, 118);
  border-radius: 50px;
  cursor: pointer;
}

#acceptTerms:checked:after {
  content: "\2713";
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}
select {
  -webkit-appearance: none;
}
.react-datepicker-wrapper {
  width: 100%;
}

.input_label {
  font-size: 12px;
}

// TAB and MOBILE

@media screen and (max-width: 500px) {
  .main_bg .right_container {
    border-left: none;
  }
}

.text-yellow{
  color:#FFCA0A;
  font-size: 16px;
  font-weight: 500;
}
