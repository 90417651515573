// Aboutus Design start
.about_conatiner {

    background: #2D2C2B;
    color: #FFFFFF;
    padding-bottom: 8rem;

    img {
        width: 100%;
        height: 550px;
        object-fit: cover;
    }

    .onepic {
        height: 5rem;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .desc-cont {
        margin: 6rem 0rem 0rem 0rem;
    }

    .aboutus-quotes-cont {
        text-align: center;
        background-image: linear-gradient(to right, #636363 1%, #2D2C2B);
        font-size: 36px;
        padding: 3rem 0rem;
    }

    .aboutus-desciption {
        color: #D8D8D8;
        font-size: 16px;
    }
}


// Aboutus Design end

.terms-of-use{
    p{
        margin-bottom: rem !important;
    }
    
    padding-bottom: 5rem;
    background: #2D2C2B;
    color: #FFFFFF;
    padding: 20px;
    
    img{
        width: 100%;
    }
}