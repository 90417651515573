.text-right {
  text-align: right;
}

.small-img{
  justify-content: start;
}

.text-left {
  text-align: left;
}

.product-highlight {

  .product-img{
    height: 550px;
    width: 550px;
  }

  .hilight-header{
    background-color: #000;
  }

  .hightlight-footer-btn-cont .hightlight-footer-btn{
    min-width: 380px;
    height: 50px;
    background-color: transparent;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #fff;
  }
  
  .header-title{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
    .title{
      font-size: 36px;
      color: #ffffff;
      max-width: 687px;
      line-height: 36px;
      font-weight: 300;
    }
    button{
      border:none;
      outline: none;
      border:1px solid #ffffff;
      border-radius: 4px;
      background-color: transparent;
      color: #fff;
      padding: 0px 95px;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
.border-red {
  border: 2px solid red;
}
.notification {
  background-color: #c20e1a;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}

.ProductCardOne {
  background-color: #000;
  .product-img { 
    height: 460px;
    width: 100%;
    padding: 0px 150px;
  }

  .prod_bg {
    height: 700px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    position: relative;
    margin-bottom: 5%;
  }

  .product-name {
    font-size: 36px;
    font-weight: 300;
    color: #fff;
  }

  .btn-container {
    display: flex;
    align-items: center;
    
    .button {
      width: 275px;
      height: 50px;
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 4px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
    }
  }

  .embed-responsive {
    position: relative;
    display: block;
    padding: 0;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
    }
  }

  .play-button-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: center;
  }
}

.ProductCardTwo {
  padding-top: 4rem;
  justify-content: space-evenly;

  .left .product-detail{
    padding-right: 2rem;
  }

  .right .product-detail{
    padding-left: 2rem;
  }

  .product-img {
    // height: 500px;
    // width: 600px;
    background-repeat: no-repeat !important;
  }

  .product-detail {
    .title {
      font-size: 36px;
      font-weight: 300;
      color: #fff;
      line-height: 40px;
    }

    .cnt {
      font-size: 16px;
      font-weight: 300;
      color: #d8d8d8;
    }
  }
}

.productCardThree {
  margin-top: 4rem;
  justify-content: center;
  // height: 1224px;
  //     width: 1224px;
 
  .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // height: 1224px;
    //   width: 1224px;
  }
  
  .image-item {
    width: 20%;
    padding: 10px;
    box-sizing: border-box;
  }

  .bg_img{
    width: 33.3%;
    float: left;
  }

  // .bg_img {
  //   // min-height: 250px;
  //   // min-width: 300px;
  //   width: 380px;
  //   height: 408px;
  //   float: left;
  //   @media screen and (max-width: 535px) {
  //     width: 200px;
  //     height: 200px;
  //   }
  //    @media screen and (min-width: 538px) and (max-width: 624px) {
  //     width: 255px;
  //     height: 255px;
  //   }
  //    @media screen and (min-width: 624px) and (max-width: 840px) {
  //     width: 300px;
  //     height: 300px;
  //   }
  //   @media screen and (max-width: 424px) {
  //     width: 148px;
  //     height: 148px;
  //   }

  //   // img {
  //   //   height: 200px;
  //   //   width: 200px;
  //   // }
  // }
}

.card-slider {
  background-color: #000;
  height: 500px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  position: relative;

  .main_container {
    position: absolute;
    top: 50%;
  }

  .main_content {
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 2px;
    text-align: left;
  }

  .sub_content {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    text-align: left;
  }

  .slideer_icon_container {
    width: 100%;
    position: absolute;
    bottom: 50px;
    display: flex;
  }

  .slideer_icon {
    background-color: #48484a;
    width: 25px;
    height: 6px;
    float: left;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .active {
    background-color: #d8d8d8;
  }
}



.card-two {
  background-color: #000;
  height: 400px;
  border-radius: 4px;
  // background-image: url(".././assets/Image-Product.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: relative;
  .top {
    width: 100%;
    height: 100%;
    img {
      // padding: 3rem;
      height: 100%;
      width: 100%;
    }
  }
  .main_container {
    position: absolute;
    bottom: 0px;
    width: 100%;

    .no-txt {
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
    }

    .name {
      font-size: 14px;
      font-weight: 400;
      color: #d8d8d8;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    .like_cnt {
      background-color: #48484a;
      border-radius: 28px;
      padding: 1px 10px;
      width: max-content;
      margin-left: auto;
      cursor: pointer;
      display: flex;
    }

    .like_img {
      width: 15px;
      height: 18px;
    }

    .like_count {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    .amount-txt,
    .time-txt {
      font-size: 12px;
      color: #a9a9a9;
      font-weight: 500;
    }

    .amount,
    .time {
      font-size: 18px;
      color: #fff;
      font-weight: 700;
    }
  }
}


.card-three {
  background-color: #000;
  border-radius: 4px;
  max-width: 264px;
  min-width: 264px;
  overflow: hidden;
  // margin: auto;

  .top {
    width: 100%;
    height: 100%;
    min-height: 264px;
    img {
      // padding: 3rem;
      height: 264px;
      width: 100%;
    }.verify-icon-home{
        width: 44.5px;
        height: 48px;
        position: absolute;
        left: 15.5px;
        top: 15.5px;
    }
  }

  .main_container {
    height: 40%;
    background-color: #2c2c2e;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    border-radius: 0px 0px 4px 4px;

    .no-txt {
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
    }

    .name {
      font-size: 14px;
      font-weight: 400;
      color: #d8d8d8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .like_cnt {
      background-color: #48484a;
      border-radius: 28px;
      padding: 1px 10px;
      width: max-content;
      margin-left: auto;
      position: absolute;
      top: -14px;
      right: 12px;
    }

    .like_img {
      width: 15px;
      height: 18px;
    }

    .like_count {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    .amount-txt,
    .time-txt {
      font-size: 12px;
      color: #a9a9a9;
      font-weight: 500;
    }

    .amount,
    .time {
      font-size: 14px;
      color: #fff;
      font-weight: 700;
    }
  }
}


.card-four {
  background-color: #000;
  width: 100%;
  overflow: hidden;
  max-width: 264px;
  min-width: 264px;
  border-radius: 4px;

  .top {
    width: 100%;
    height: 100%;
    min-height: 264px;
    img {
      // padding: 3rem;
      height: 264px;
      width: 100%;
    }.verify-icon-home{
        width: 30px !important;
        height: 30px !important;
        position: absolute;
        left: 15.5px;
        top: 15.5px;
    }
  }

  .main_container {
    height: 40%;
    background-color: #2c2c2e;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;

    .no-txt {
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
    }

    .name {
      font-size: 14px;
      font-weight: 400;
      color: #d8d8d8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .like_cnt {
      background-color: #48484a;
      border-radius: 28px;
      padding: 1px 10px;
      width: max-content;
      margin-left: auto;
      position: absolute;
      top: -14px;
      right: 12px;
    }

    .like_img {
      width: 15px;
      height: 18px;
    }

    .like_count {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    .amount-txt,
    .time-txt {
      font-size: 12px;
      color: #a9a9a9;
      font-weight: 500;
    }

    .amount,
    .time {
      font-size: 14px;
      color: #fff;
      font-weight: 700;
    }
  }
}

.send-item-section {
  text-align: center;

  .header {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
  }

  .content {
    color: #d8d8d8;
    font-size: 16px;
    font-weight: 300;
  }

  .send-item-section-btn {
    height: 45px;
    width: 200px;
    background-color: #fff;
    border-radius: 4px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}

.why-us {
  background-color: #1c1c1e;

  .header {
    font-size: 18px;
    color: #fff;
    font-weight: 400;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  .content {
    font-size: 14px;
    color: #d8d8d8;
    font-weight: 300;
  }
}
.refund_bgimg {
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  width: 100%;
}
.refund-modal-content {
  position: relative;
  top: 0rem;
  text-align: center;
  .title {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0rem !important;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    padding-top: 10px;
  }
  .sub-cnt {
    font-size: 14px;
    line-height: 22px;
  }
  .footer-bottom {
    margin: 0px 2rem;
  }
  .refund-popupwinner {
    .paywithintime {
      font-size: 14px;
      text-decoration: underline;
      color: #ffffff;
    }
    .paywithintext {
      font-size: 14px;
      color: #d8d8d8;
    }
  }
}
//Modal Popup
.modal {
  --bs-modal-width: 400px !important;
}

.modal-content {
  min-height: 500px;
  padding: 20px 20px;
  padding-bottom: 3rem;
  background-color: #1c1c1e !important;
  color: #ffffff !important;
  overflow: hidden;

  p {
    margin-bottom: 0rem;
  }

  img {
    // display: flex;
    align-self: flex-end;
    cursor: pointer;
    color: #ffffff;
  }

  .register-member {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }

  .register_button {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background-color: transparent;
    color: #d8d8d8;
    font-size: 18px;
    padding: 10px 0px 10px 0;
  }

  .forgot-account {
    font-size: 14px;
    cursor: pointer;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
  }

  .terms_and_condition {
    display: flex;
    align-items: flex-start;

    .terms_and_condition_text {
      color: #d8d8d8;
      font-size: 14px;
      padding-left: 2rem;

      span {
        text-decoration: underline;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .input-group-text {
      background-color: transparent;
      border: none;
      padding: 0rem 0.75rem 0rem 0rem !important;
    }

    .form-check-input[type="checkbox"] {
      border-radius: 50%;
      width: 1em;
      height: 1em;
    }
  }
}

.add-address-modal {
  .dropdown {
    button {
      background-color: #48484a;
      border: none;
      border-radius: 4px;
      width: 100%;
      height: 45px;
      margin-top: 10px;
      text-align: left;
    }
  }
}

.login-error-modal {
  color: #fff;
  .close-img {
    width: 100px;
    height: 100px;
  }

  .content {
    font-size: 24px;
    font-weight: 300;
  }

  .sub-cnt {
    font-size: 14px;
    font-weight: 700;
  }

  button {
    width: 100%;
    background-color: transparent;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    color: #fff;
    height: 45px;
  }
}

.payment-complete {
  color: #fff;
  .close-img {
    width: 100px;
    height: 100px;
  }

  .cnt-container {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cnt {
    font-size: 24px;
    font-weight: 300;
  }

  button {
    width: 100%;
    background-color: #1c1c1e;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 45px;
    font-size: 18px;
  }
}

.payment-modal {
  color: #fff;
  .close-img {
    width: 100px;
    height: 100px;
  }

  .prod-img{
    width: 135px;
    height: 135px;
  }

  .main-cnt {
    font-size: 24px;
    font-weight: 300;
  }

  .sub-cnt {
    font-size: 14px;
    font-weight: 300;
    color: #d8d8d8;
  }

  button {
    width: 100%;
    background-color: #2c2c2e;
    border: 1px solid #2c2c2e;
    border-radius: 4px;
    color: #737576;
    height: 45px;
    font-size: 18px;
  }

  .white-btn {
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: #000;
  }

  table {
    width: 100%;

    .border-btm {
      border-bottom: 1px solid #48484a;
    }
  }

  .tbl-txt1 {
    color: #d8d8d8;
    font-size: 12px;
    font-weight: 300;
  }

  .tbl-txt2 {
    font-size: 14px;
    font-weight: 600;
  }

  .tbl-num1 {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 0px;
  }

  .tbl-num2 {
    font-size: 24px;
    font-weight: 700;
    padding: 10px 0px;
  }
}

.add-bank-modal {
  color: #fff;

  .header {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
  }
  .dropdown {
    button {
      background-color: #48484a;
      border: none;
      border-radius: 4px;
      width: 100%;
      height: 45px;
      margin-top: 10px;
      text-align: left;
    }
  }

  .cancel_button {
    color: #737576 !important;
    background-color: #2c2c2e !important;
    border: none;
  }
}

// TAB and MOBILE



.banner-parent {
  margin: 0;
  width: 100%;
  height: auto;
  position: relative;
  min-height: 640px;
  margin-top: 2rem !important;

  .video-width {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 640px;
    object-fit: cover;
    bottom: 0;
    top: 0;
  }

  .banner-content {
    // background-color: #000;
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    .main_container {
      position: relative;
      top: 50%;
      left:29.23px;
      width: 482px;
      top:326px;
    }

    .main_content {
      font-size: 36px;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 2px;
      text-align: left;
       width: 80%;
    }

    .sub_content {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.4;
      text-align: left;
      width: 40%;
    }
  }
}

.red-btn {
  background-color: red($color: rgba(151, 0, 0, 0.559));
}
.mobile_view{
  display: none !important;
}

/* CSS for desktop */
.desktop-image {
  display: block; /* Display desktop image */
}

.hot-item{
  .card-three{
    max-width: 362px;
    min-width: 362px;
    margin: 0 auto;
  }
  .card-primary-header{
        justify-content: center;
  }
}

.mb-carousel{
  display: none !important;
}
/* CSS for mobile */
@media only screen and (max-width: 700px) {

  
}


.minus_deposit{
  color:#FF3C49;
}

.highlight-mobile-view{
  display: none;
}

.highlight-disktop-view{
  display: block;

  .top{
    height: 362px;
    width: 362px;
    background-position: center !important;
    background-size: cover !important;
  }

  .main_container{
    position: absolute;
    bottom: 0;
    background-color: transparent;
    width: 100%;
    .like_cnt{
      top: auto !important;
    }
  }
}



/* Mobile devices (portrait) and tablets (portrait) */
@media only screen and (max-width: 1023px) {
  /* Your CSS styles for both mobile devices and tablets in portrait orientation here */

  .product-highlight .header-title .title{
    font-size: 24px;
    line-height: 24px;
    width: 65%;
  }
  .product-highlight .header-title button{
    padding: 0px;
    width: 107px;
    height: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .hilight-header{
    background-color: transparent !important;
    padding: 0px 12px;
  }


.highlight-disktop-view{
  display: none;
}

.highlight-mobile-view{
  display: block; 
}

.highlight-mobile-view.card-three .top img{
  height: 345px !important;
}

.hot-item{
  padding-left: 0px !important;
  padding-right: 0px !important;
  .card-three{
    min-width: 345px !important;
    max-width: 345px !important;
    margin: 1.5rem auto 0 auto !important;
  }
}

.card-primary-header a {
  margin-left: auto;
}
.desktop-image {
  display: none; /* Hide desktop image on mobile */
}
.mobile-image {
  display: block; /* Display mobile image */
}

.hot-item{
  .card-three{
    max-width: 100%;
    min-width: 100%;
  }
}

.card-three{
  max-width: 165px;
  min-width: 165px;

  .top{
    min-height:165px !important;
  }

  .top img{
    height: 165px;
  }

  .top img.verify-icon-home{
    width: 30.5px;
    height: 30px !important;
    position: absolute;
    left: 15.5px;
    top: 15.5px;
  }
  .main_container .name{
    margin-top: 10px;
  }
}
.dk-carousel{
  display: none !important;
}
.mb-carousel{
  display:block !important;
}


.hot-item .card-primary-header{
  //text-align: left !important;
  justify-content: start !important;
}
.product {
  .header-title{
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    h3{
      font-size: 24px;
      color: #ffffff;
    }
    button{
      border:none;
      outline: none;
      border:1px solid #ffffff;
      border-radius: 4px;
      background-color: transparent;
      color: #fff;
      padding: 0px 1rem;
      height: 36px;
    }
  }
}
.ProductCardOne {
  .product-img{
    height: 200px !important;
    padding: 0rem 1rem;
  }
}
.ProductCardTwo {
  padding: 1rem 12px 0rem 12px;

  .card-cont{
    flex-direction: column;
  }

  .right .product-detail{
    padding-left: 0px;
  }
  .left .product-detail{
    padding-right: 0px;
  }

  .col-reverse{
    flex-direction:column-reverse;
  }
  // padding-top: 2rem;
  .mobile_view{
    display: block !important;
  }
  .product-img {
    background-repeat: no-repeat !important;
    height: 372px;
    width: 372px;
    margin-top: 25px;
    margin: 25px auto 0px;
  
    @media screen and (max-width:465px) {
      height: 300px;
      width: 300px;
    }
  }

.product-detail {
  .title {
    line-height: 40px;
    margin-top: 1rem;
  }

  .cnt {
    padding-top: 24px !important;
  }
}

}

.productCardThree{
  padding: 1rem 12px 0rem 12px;
  .bg_img
  {
    width: 50%;
    float: left;
  }
}
.hightlight-footer-btn-cont{
  padding: 1rem 12px 0rem 12px;

}
.product-highlight .hightlight-footer-btn-cont .hightlight-footer-btn{
  min-width: 100% !important;
}

.hot-item .card-three .main_container .like_cnt{
  float: right;
  margin-top: 0px;
}

.card-three {
  // min-height: 500px;
  .top {
    height: 40%;
  }

  .main_container {
    height: auto;
    min-height: 60%;

    .like_cnt {
      position: relative;
      top: 0;
      right: 0;
      margin-left: 12px;
      margin-top: 1.5rem;
    }

  }
}

.bidding-card {
  .card-four {
    border-radius: 4px;
    width: 100%;
    height: 174px;
    min-width: 100%;
    .top {
      height: 100%;
      width: 50%;
      float: left;
      min-height: 100%;
      img {
        // padding: 1rem !important;
        height: 174px;
        width: 174px;
      }
    }

    .main_container {
      height: 100%;
      width: 50%;
      float: right;
      min-height: auto !important;

      div:nth-child(2){
        flex-direction: column-reverse;
      }
      .like_cnt {
        background-color: #48484a;
        border-radius: 28px;
        padding: 1px 10px;
        width: max-content;
        margin-left: auto;
        position: absolute !important;
        bottom: 65px !important;
        right: 12px !important;
        top: auto !important;
      }

      .no-txt {
        margin-top: 0rem;
      }
    }
  }
}

.ProductCardOne .product-name {
  font-size: 24px;
  padding: 5px !important;
  text-align: center !important;
}

.ProductCardOne .btn-container {
  align-items: center;
  button {
    margin-right: auto !important;
  }
}

.ProductCardOne .prod_bg {
  height: 80vh !important;
}

// .productCardThree {
//   padding: 3rem 0rem 0rem;
//   justify-content: center;
//   .image-grid{
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: flex-start;
//         height: 1224px;
//       width: 1224px;
//     @media screen and (max-width: 500px) {
//       height: 562.5px;
//     width: 375px;
//     }
//     // width: auto !important;
//   }
//     .bg_img{
//       height: 187.5px;
//       width: 187.5px;
//     }
  
// }

.product .participate-btn {
  width: 90% !important;
  margin: 30px 0px;
}


.banner-parent {

  min-height: 350px;
  margin-top: 0px !important;
  .video-width {
    height: 350px;
    width: 100%;
    bottom: auto;
    top: auto;
  }

  .banner-content {

    .main_content {
      display: none;
    }

    .sub_content {
      display: none;
    }
  }
}

// .carousel-inner .carousel-item{
//   height: 300px !important;
// }
}

@media only screen and (max-width:465px) {
  .banner-parent {

    min-height: 211px !important;
    margin-top: 0px !important;
    .video-width {
      height: 211px !important;
      width: 100%;
      bottom: auto;
      top: auto;
    }
  
    .banner-content {
  
      .main_content {
        display: none;
      }
  
      .sub_content {
        display: none;
      }
    }
  }
  
}
